import Vue from 'vue'
import VueRouter from 'vue-router'
import Payment from '../components/Payment.vue';

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Payment',
        component: Payment,
        props: true
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  })
  
export default router