<template>
    <div class="bg-appBackground pb-10 h-screen">

        <!-- Disclaimer Modal -->
        <div>
            <div v-if="toggleModal" 
            class="fixed overflow-x-hidden overflow-y-auto inset-0 flex justify-center items-center z-50">
                <div 
                class="relative mx-auto w-auto max-w-2xl flex flex-col">
                    <div 
                    class="w-full bg-white rounded-lg shadow-2xl">
                        <div class="px-4 py-2 items-center flex space-x-2">
                            <p>Important Disclaimer</p>
                            <i class="ml-2 fas fa-exclamation-triangle"></i>
                        </div>
                        <div class="p-4">
                            <span>Post form submission, you will be redirected to a third party service (Eduvance) to start your EMI application process. Kindly make sure to complete all the upcoming steps and upload the required documents to successfully apply for your EMI.</span>
                        </div>
                        <div class="p-4">
                            <button @click="toggleModal=false" class="ml-72 text-sm text-blue h-9 px-4 rounded-full border-2">Close</button>
                        </div>
                    </div>
                </div>

            </div>
            
            <div v-if="toggleModal" class="absolute inset-0 z-40 opacity-25 overflow-y-auto"></div>
        </div>
        <!-- End Disclaimer Modal -->

        


        <div v-if="!res_valid">
            <!-- header -->
            <nav class="flex items-center justify-between bg-yocketPrimary flex-wrap bg-teal-500 p-2">
            <div class="flex items-center flex-shrink-0 text-white mr-6">
                <img src="../assets/images/yocket-logo-white.png" class="object-scale-down h-10 w-full mr-6">
            </div>
            <div class="block lg:hidden">
                <button class="flex items-center px-3 py-2 border rounded-lg text-teal-200 border-teal-400">
                <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
                </button>
            </div>
            <div class="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
                <div>
                <a href="https://www.yocket.com" class="inline-block text-sm px-4 py-2 leading-none hover:border hover:rounded-lg text-white border-white border-transparent hover:text-teal-500 mt-4 lg:mt-0">Home</a>
                </div>
            </div>
            </nav>
            
            <!-- <div class="flex-col container h-screen w-full mt-4 overflow-y-auto"> -->
            <div class="w-full p-10">
                <div class="mx-auto flex justify-center items-center bg-white rounded-lg-lg px-4 py-6 max-w-lg rounded-lg shadow-lg">

                    <!-- TODO: Put one div here for part one and justify it to left -->
                    <form method="post" class="w-full" :action="URL">
                        <!--Form Body part 1-->
                        <!-- <div class="float-left justify-between"> -->
                            <div class="flex flex-wrap -mx-3 mb-6">
                            
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                                        First Name *
                                    </label>
                                    <input @change="validateAll()" v-model="firstName" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" :class="{'border-red-500': !firstname_valid}" type="text" name="requestParam[applicant][first_name]" id="m_requestParam[applicant][first_name]" value="" placeholder="First Name"/>
                                    <p v-if="!firstname_valid" class="text-red-500 text-xs mt-3 italic">Please fill out this field properly.</p>
                                </div>

                                <div class="w-full md:w-1/2 px-3">
                                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                                        Last Name *
                                    </label>
                                    <input @change="validateAll()" v-model="lastName" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" :class="{'border-red-500': !lastname_valid}" type="text" name="requestParam[applicant][last_name]" id="m_requestParam[applicant][last_name]" value="" placeholder="Last Name"/>
                                    <p v-if="!lastname_valid" class="text-red-500 text-xs mt-3 italic">Please fill out this field properly.</p>
                                </div>
                            </div>

                            <!-- HIDDEN USERNAME FIELD -->
                            <input type="hidden" name="userName" id="m_userName" :value="ID" placeholder="User Name"/>
                            
                            <!-- HIDDEN PASSWORD FIELD -->
                            <input type="hidden" name="password"  id="m_password" :value="PASSWORD" placeholder="Password">
                            
                            <!-- HIDDEN UUID FIELD -->
                            <input type="hidden" name="meta_data" id="m_meta_data" :value="email" placeholder="meta_data">
                             <!-- HIDDEN CLIENT ID -->
                            <input type="hidden" name="requestParam[client_course_id]" id="m_requestParam[client_course_id]" :value="CLIENT_ID">
                             <!-- HIDDEN LOCATION ID -->
                            <input type="hidden" name="requestParam[client_location_id]" id="m_requestParam[client_location_id]" :value="LOCATION_ID">
                             <!-- HIDDEN INSTITUE ID -->
                            <input type="hidden" name="requestParam[client_institute_id]" id="m_requestParam[client_institute_id]" :value="INSTITUTION_ID">
                            

                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full px-3">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-city">
                                    Loan Amount *
                                </label>
                                <input @change="validateAll()" v-model="loanAmount" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" :class="{'border-red-500': !loanAmount_valid}" type="text" name="requestParam[loan_amount]" id="m_requestParam[loan_amount]" value="" placeholder="Loan Amount Eg. 1200000">
                                <p v-if='loanAmount_valid' class="text-xs mt-3 italic">You have entered: {{toWords}} Rupees</p>
                                <p v-if="!loanAmount_valid" class="text-red-500 text-xs mt-3 italic">Please fill out this field properly.</p>
                                <p v-else-if="!loanAmountInRange" class="text-red-500 text-xs mt-3 italic">Loan amount must be in between 10 Thousand and 30 Lacs</p>
                                </div>
                            </div>

                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full px-3">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-city">
                                    Phone *
                                </label>
                                <input @change="validateAll()" v-model="phone" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" :class="{'border-red-500': !phone_valid}" type="text" name="requestParam[applicant][mobile_number]" id="m_requestParam[applicant][mobile_number]" value="" placeholder="Mobile Number">
                                <p v-if="!phone_valid" class="text-red-500 text-xs mt-3 italic">Please fill out this field properly.</p>
                                </div>
                            </div>

                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full px-3">
                                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="loanamount">
                                        Email *
                                    </label>
                                    <input @change="validateAll()" required v-model="email" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" :class="{'border-red-500': !email_valid}" type="text" name="requestParam[applicant][email_id]" id="m_requestParam[applicant][email_id]" value="" placeholder="Email Id">
                                    <p v-if="!email_valid" class="text-red-500 text-xs mt-3 italic">Please fill out the field properly.</p>
                                </div>
                            </div>

                        
                        <!-- Button div -->
                            <div class="flex justify-between space-x-2 items-center">
                                <button :disabled="!submitEnable" type="submit" class="text-sm text-blue h-9 px-4 rounded-full border-2">
                                    Submit Details
                                </button>
                                <div @click="toggleModal=true" class="font-thin ml-2 hover:text-yocketPrimary cursor-pointer"> Read Disclaimer </div>
                            </div>
                        
                    </form>


                
                </div>
            </div>
        </div>
</div>
    
</template>

<script>
import 'vue2-datepicker/index.css';

export default {
    name: 'Payment',

    data(){
        return {
            toggleModal:true,
            uuid:'',
            firstName:'',
            lastName:'',
            email:'',
            phone:'',
            aadhar:'',
            panCard:'',
            applicationId:'',
            loanAmount:'',
            dob:'',
            firstname_valid:true,
            lastname_valid:true,
            email_valid:true,
            phone_valid:true,
            loanAmount_valid:true,
            loanAmountInRange:true,
            user: [],
            res_valid:false,
            res:'',
            submitEnable: false,
            toWords:'Zero',
            URL: "",
            ID:"",
            PASSWORD: "",
            CLIENT_ID: "",
            LOCATION_ID: "",
            INSTITUTION_ID: ""


        }
    },
    methods: {

        validateAll (){

            if(!this.firstName){
                this.firstname_valid = false;
            }else{
                this.firstname_valid = true;
            }
            if(!this.lastName){
                this.lastname_valid = false;
            }else{
                this.lastname_valid = true;
            }
            if(!this.email){
                
                this.email_valid = false;
            }else{
                if(this.validateEmail()){
                    this.email_valid = true;
                }else{
                    this.email_valid = false;
                }
            }
            if(!this.phone){
                
                this.phone_valid = false;
            }else{
                if(this.validatePhone()){
                    this.phone_valid = true;
                }else{ 
                    this.phone_valid = false;
                }
            }
            if(!this.loanAmount){
                this.loanAmount_valid = false;
            }else{
                if(this.validateLoanAmount()){
                this.loanAmount_valid = true;
                this.loanAmountInRange=true;
                this.toWords=this.convertNumberToWords(this.loanAmount);
                if(parseInt(this.loanAmount) < 10000 || parseInt(this.loanAmount) > 3000000){
                    
                    this.loanAmountInRange=false;
                    this.toWords='';
                }
                }else{
                    this.toWords='';
                    this.loanAmount_valid = false;
                }

            }

            if(!this.firstname_valid || !this.lastname_valid || !this.email_valid || !this.phone_valid || !this.loanAmount_valid  || !this.loanAmountInRange){
                this.submitEnable=false;
            }else{
                
                this.submitEnable = true;
            }

        },
        convertNumberToWords(amount) {
            var words = new Array();
            words[0] = '';
            words[1] = 'One';
            words[2] = 'Two';
            words[3] = 'Three';
            words[4] = 'Four';
            words[5] = 'Five';
            words[6] = 'Six';
            words[7] = 'Seven';
            words[8] = 'Eight';
            words[9] = 'Nine';
            words[10] = 'Ten';
            words[11] = 'Eleven';
            words[12] = 'Twelve';
            words[13] = 'Thirteen';
            words[14] = 'Fourteen';
            words[15] = 'Fifteen';
            words[16] = 'Sixteen';
            words[17] = 'Seventeen';
            words[18] = 'Eighteen';
            words[19] = 'Nineteen';
            words[20] = 'Twenty';
            words[30] = 'Thirty';
            words[40] = 'Forty';
            words[50] = 'Fifty';
            words[60] = 'Sixty';
            words[70] = 'Seventy';
            words[80] = 'Eighty';
            words[90] = 'Ninety';
            amount = amount.toString();
            var atemp = amount.split(".");
            var number = atemp[0].split(",").join("");
            var n_length = number.length;
            var words_string = "";
            if (n_length <= 9) {
                var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
                var received_n_array = new Array();
                for (var i = 0; i < n_length; i++) {
                    received_n_array[i] = number.substr(i, 1);
                }
                for (let i = 9 - n_length, j = 0; i < 9; i++, j++) {
                    n_array[i] = received_n_array[j];
                }
                for (let i = 0, j = 1; i < 9; i++, j++) {
                    if (i == 0 || i == 2 || i == 4 || i == 7) {
                        if (n_array[i] == 1) {
                            n_array[j] = 10 + parseInt(n_array[j]);
                            n_array[i] = 0;
                        }
                    }
                }
                let value = "";
                for (let i = 0; i < 9; i++) {
                    if (i == 0 || i == 2 || i == 4 || i == 7) {
                        value = n_array[i] * 10;
                    } else {
                        value = n_array[i];
                    }
                    if (value != 0) {
                        words_string += words[value] + " ";
                    }
                    if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
                        words_string += "Crores ";
                    }
                    if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
                        words_string += "Lakhs ";
                    }
                    if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
                        words_string += "Thousand ";
                    }
                    if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
                        words_string += "Hundred and ";
                    } else if (i == 6 && value != 0) {
                        words_string += "Hundred ";
                    }
                }
                words_string = words_string.split("  ").join(" ");
            }
            return words_string;
        },

        validateFirstName(){
            if(!this.firstname_valid){
                this.firstname_valid = false;
            }else{
                this.firstname_valid = true;
            }
        },
        validateLastName(){
            if(!this.lastname_valid){
                this.lastname_valid = false;
            }else{
                this.lastname_valid = true;
            }
        },

        validatePhone(){
            var phonenoRegex = /^\d{10}$/;
            return phonenoRegex.test(this.phone); 
        },
        
        
        validateEmail(){
            var emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return emailRegex.test(this.email);
        },
        validateLoanAmount(){
            var loanRegex = /^[0-9]+$/
            return loanRegex.test(this.loanAmount);
        },
        checkSubmit(){
            if(this.firstname_valid && this.lastname_valid && this.email_valid && this.phone_valid && this.aadhar_valid && this.panCard_valid && this.loanAmount_valid && this.dob_valid && this.loanAmountInRange){
                this.submitEnable=true;
            }else{
                this.submitEnable=false;
            } 
        },
        
        async onSubmit(){
            
            this.uuid = this.$route.params.id;
            if(!this.firstName){
               
                this.firstname_valid = false;
            }else{
                this.firstname_valid = true;
            }
            if(!this.lastName){
               
                this.lastname_valid = false;
            }else{
                this.lastname_valid = true;
            }
            if(!this.email){
               
                this.email_valid = false;
            }else{
                if(this.validateEmail()){
                    this.email_valid = true;
                }else{
                    this.email_valid = false;
                }
            }
            if(!this.phone){
               
                this.phone_valid = false;
            }else{
                if(this.validatePhone()){
                    this.phone_valid = true;
                }else{ 
                    this.phone_valid = false;
                }
            }
            if(!this.loanAmount){
                this.loanAmount_valid = false;
            }else{
                if(this.validateLoanAmount()){
                this.loanAmount_valid = true;
                this.loanAmountInRange=true;
                if(parseInt(this.loanAmount) < 10000 || parseInt(this.loanAmount) > 3000000){
                    this.loanAmountInRange=false;
                }
                }else{
                    this.loanAmount_valid = false;
                }

            }
            if(!this.firstname_valid || !this.lastname_valid || !this.email_valid || !this.phone_valid || !this.loanAmount_valid || !this.loanAmountInRange){
                return
            }else{
                this.submitEnable = true;
            }
            
        }

    },

    watch:{
        loanAmount(newVal, oldVal){
            if(newVal !== oldVal){
                this.toWords = this.convertNumberToWords(newVal);
            }
        }
    },

    mounted () {
        this.URL = process.env.VUE_APP_URL
        this.ID = process.env.VUE_APP_ID
        this.PASSWORD = process.env.VUE_APP_PASSWORD
        this.INSTITUTION_ID = process.env.VUE_APP_CLIENT_INSTITUTE_ID
        this.CLIENT_ID = process.env.VUE_APP_CLIENT_COURSE_ID
        this.LOCATION_ID = process.env.VUE_APP_CLIENT_LOCATION_ID

    }

}
</script>

<style src="..\assets\css\tailwind.css"/>